import { graphql } from 'gatsby';
import React from 'react';
import { Header, Item, ItemList, Layout, Pagination, SEO } from '../components';
import Data from '../models/Data';
import { queryResultToPresentationList } from '../utils/contentList';

interface Props {
  data: Data;
  pageContext: {
    currentPage: number;
    totalPages: number;
  };
}

const BlogPage = ({ data, pageContext }: Props) => {
  const blogs = queryResultToPresentationList(data, ['blog']);

  return (
    <Layout>
      <SEO isPost={false} titlePrefix={'Blog | '} />
      <Header title='Blog' icon='document-edit' subHeading='Engineering & UX Blog' />
      <ItemList>
        {blogs.map((blog, idx) => (
          <Item key={idx} {...blog} />
        ))}
      </ItemList>
      <Pagination currentPage={pageContext.currentPage} totalPages={pageContext.totalPages} url='blog'/>
    </Layout>
  );
};

export default BlogPage;

export const BlogQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: $limit, skip: $skip) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD.MM.YYYY")
            category
            image {
              publicURL
            }
          }
          excerpt(pruneLength: 200)
          timeToRead
        }
      }
    }
  }
`;
